.add-sub-details-form .asterisk {
  color: #e51837;
  margin-left: 2px;
}
.add-sub-details-form .tds-label {
  font-weight: 700;
  color: #333333;
}
.add-sub-details-form .invalid-feedback {
  font-size: 100%;
}
.was-validated .add-sub-details-form .form-check-input:valid ~ .form-check-label {
  color: inherit;
}
.was-validated .add-sub-details-form .form-control:valid {
  border-color: #ced4da;
}
