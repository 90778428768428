.orderInvoiceModal {
  color: #555555;
}
@media print {
  .orderInvoiceModal {
    width: 100%;
  }
}
.orderInvoiceModal .total-table {
  min-width: 15vw;
}
.orderInvoiceModal .total-table td {
  padding: 2px;
}
.orderInvoiceModal .total-table td:first-child {
  font-weight: bold;
}
.orderInvoiceModal .total-table tbody tr:last-child td:last-child {
  font-weight: bold;
}
@media screen and (min-width: 576px) {
  .orderInvoiceModal .table {
    min-width: 50vw;
  }
}
@media (min-width: 768px) {
  .orderInvoiceModal .table {
    min-width: 50vw;
  }
}
@media (min-width: 1200px) {
  .orderInvoiceModal .table {
    min-width: 40vw;
  }
}
.orderInvoiceModal .table thead th {
  border-top: none;
}
.orderInvoiceModal .table thead th:first-child {
  padding-left: 0;
}
.orderInvoiceModal .table thead th:last-child {
  padding-right: 0;
}
.orderInvoiceModal .table tbody tr td:first-child {
  padding-left: 0;
}
.orderInvoiceModal .table tbody tr td:last-child {
  padding-right: 0;
}
.orderInvoiceModal .table tbody tr:last-child td {
  border-bottom: 1px solid #dee2e6;
}
.orderInvoiceModal p {
  line-height: 1.2;
}
.orderInvoiceModal th {
  font-weight: bold;
  border-bottom: none !important;
}
.orderInvoiceModal .discount-row {
  color: #53872f;
}
.orderInvoiceModal .refund-box {
  border-top: 1px solid #DDDDDD;
  padding: 1rem;
  margin-top: 2rem;
}
.orderInvoiceModal .refund-box .refund-title {
  font-size: 1.125rem;
  font-weight: bold;
  text-transform: uppercase;
}
.orderInvoiceModal .refund-box table td {
  padding: 2px;
}
.orderInvoiceModal .refund-box .refund-item {
  background-color: #f0f0f0;
  border: 1px solid #DDDDDD;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
}
