.refund-confirmation {
  margin-top: 1rem;
}
.refund-confirmation .total-table {
  min-width: 15vw;
}
.refund-confirmation .total-table td {
  padding: 2px;
}
.refund-confirmation .refund-row {
  font-weight: bold;
}
