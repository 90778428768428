.promoCodesPage .itemHeader {
  margin-top: 2em;
  margin-bottom: 1rem;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
}
.promoCodesPage .container {
  padding-right: 0;
}
.promoCodesPage .exportButton {
  margin-top: 2rem;
}
.promoCodesPage .searchPromoCode {
  border: 1px solid #ddd;
  padding: 1rem;
  background-color: #f0f0f0;
  margin-bottom: 2rem;
}
.promoCodesPage .searchButton {
  width: 100%;
}

.createPromoPage .tdsInput {
  padding-right: 15px;
}
