.searchPromoCode {
  display: inline-flex;
}
.searchPromoCode .col-tds-datepicker {
  flex: 0 0 110px;
  max-width: 110px;
  padding: 0 15px;
  display: inline-flex;
}
.searchPromoCode .tds-date-width {
  width: 5rem;
}
.searchPromoCode .tds-promo-code {
  min-width: 8.5rem;
  padding: 0 15px;
}
.searchPromoCode .tds-promo-select {
  min-width: 12rem;
  padding: 0 15px;
}
.searchPromoCode .tds-promo-sku-select {
  min-width: 16rem;
  padding: 0 15px;
}
.searchPromoCode .tds-datepicker-option {
  min-width: 12rem;
}
.searchPromoCode .select__message {
  min-height: 1rem;
  line-height: 1rem;
}
