.userStatusSearch {
  border: 1px solid #ddd;
  padding: 1rem;
  background-color: #f0f0f0;
  display: inline-flex;
  margin-bottom: 2rem;
}
.userStatusSearch .tds-user-status-select {
  min-width: 17rem;
  padding: 0 15px;
}
.userStatusSearch .tds-region-select {
  min-width: 12rem;
  padding: 0 15px;
}
