.subscription-detail .summary-table {
  margin-top: 2rem;
}
.subscription-detail .summary-table td {
  padding: 0.125rem 1rem;
}
.subscription-detail .row-current {
  font-weight: bold;
}
.subscription-detail .row-past {
  font-style: italic;
}
