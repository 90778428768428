.tdsSettingsPage .tdsSettingHeader {
  margin-top: 2em;
  margin-bottom: 1rem;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
}
.tdsSettingsPage .text-gray {
  color: #555555;
}
.tdsSettingsPage .button-save {
  margin-bottom: 1.5rem;
}
.tdsSettingsPage .region-name {
  margin-bottom: -2em;
}
