.notification-content .message-card {
  padding: 1.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.notification-content .message-card textarea {
  width: 100%;
  border: 1px solid #DDDDDD;
}
.notification-content .message-card .asterisk {
  color: #e51837;
  margin-left: 2px;
}
.was-validated .notification-content .form-control:valid, .was-validated .notification-content .form-control:valid:focus {
  border-color: #DDDDDD;
  box-shadow: none;
}
