.refund-details .refund-col {
  width: 300px;
}
.refund-details .refund-col .tds-radio {
  min-width: 80px;
}
.refund-details .refund-col .tds-radio .form-check-inline {
  display: block;
}
.refund-details .refund-col .textField__container {
  padding-top: 0.5rem;
}
.refund-details .refund-value {
  min-width: 100px;
  margin-right: 1rem;
}
.refund-details .total-table {
  min-width: 15vw;
}
.refund-details .total-table td {
  padding: 2px;
}
.refund-details .total-table td:first-child {
  font-weight: bold;
}
.refund-details .total-table tbody tr:last-child td:last-child {
  font-weight: bold;
}
.refund-details .discount-row {
  color: #53872f;
}
.refund-details .refund-type-selection {
  border: 1px solid #DDDDDD;
  padding: 1rem;
  margin-bottom: 1rem;
}
.refund-details .refund-type-selection .tds-radio {
  margin-bottom: 0;
}
