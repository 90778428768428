.appHeader {
  background-color: #333333;
  padding: 15px;
}
.appHeader .logo-header {
  float: left;
}
.appHeader .login {
  float: right;
  margin-right: 5px;
  margin-top: 5px;
}
.appHeader .logout-div {
  float: right;
  margin-right: 5px;
  margin-top: 5px;
}
.appHeader .logout-div .navItem {
  padding: 0;
}
.appHeader .logout-div .navItem .menu {
  font-family: Arial, sans-serif;
  color: #fff;
}
.appHeader .logout-div .navItem .menu .menu__list .menuItem {
  font-family: Arial, sans-serif;
}
.appHeader .logout {
  margin-right: 5px;
  margin-top: 5px;
}
.appHeader .navbar {
  box-shadow: none;
  padding: 0;
}
.appHeader .navbar .navbar__container {
  padding: 0;
}
.appHeader .navbar .appTitle:focus, .appHeader .navbar .appTitle:hover {
  background-color: transparent;
}
.appHeader .tdsNavbar {
  font-family: Arial, sans-serif;
  background-color: #333333;
  color: #fff;
}
.appHeader .tdsNavbar .navItem {
  font-family: Arial, sans-serif;
}
.appHeader .tdsNavbar .navItem > span > a {
  color: #fff;
}
.appHeader .tdsNavbar .navItem:hover {
  background-color: inherit;
}
.appHeader .tdsNavbar .navItem > span > a:hover,
.appHeader .tdsNavbar .navItem > span > a.active {
  color: #fff;
  background-color: transparent;
  border-bottom: 2px solid #e51837;
  padding-bottom: 3px;
}
.appHeader .tdsNavbar .navItem > span > a:hover:focus,
.appHeader .tdsNavbar .navItem > span > a.active:focus {
  background-color: transparent;
}
.appHeader .tdsNavbar .navItemGroup li {
  margin-left: 0.5rem;
}
.appHeader .tdsNavbar .navItemGroup li .navItem {
  color: #fff;
}
.appHeader .tdsNavbar .navItemGroup li .navItem .panel__container form {
  color: #333333;
}
.appHeader .tdsNavbar .navItemGroup li .navItem .panel__container form a {
  color: #347ce9;
}
.appHeader .tdsNavbar .navItemGroup li:first-child .navItem {
  padding: 0;
}
.appHeader .tdsMenu {
  font-family: Arial, sans-serif;
  color: #fff;
}
.appHeader .tdsMenu .menu__list {
  font-family: Arial, sans-serif;
  z-index: 2;
}
.appHeader .tdsMenu .menu__list .menuItem {
  font-family: Arial, sans-serif;
}
.appHeader .tdsLanguage {
  margin-left: 10px;
  background: inherit;
  padding: 0;
  position: relative;
}
.appHeader .tdsLanguage .tdsMenu .menu__list--left {
  left: auto;
  right: 0;
}
.appHeader .tdsLanguage:hover {
  background-color: rgba(255, 255, 255, 0.28) !important;
}
