.notificationsPage .tds-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  border-bottom: 1px solid #555555;
  margin-block-start: 0;
  padding-inline-start: 0;
  padding: 0;
}
.notificationsPage .tds-tabs .tds-tab {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
.notificationsPage .tds-tabs .tds-tab > button {
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #333333;
  font-weight: 500;
  border: none;
  padding: 0;
  background-color: transparent;
}
.notificationsPage .tds-tabs .tds-tab > button.active {
  border-bottom: 4px solid #005dab;
}
