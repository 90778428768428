.audit-search-form {
  border: 1px solid #DDDDDD;
  padding: 1rem;
  background-color: #f0f0f0;
  display: inline-flex;
}
.audit-search-form .tdsInput {
  margin-bottom: 0 !important;
  min-width: 13rem;
}
.audit-search-form .col-order-number {
  min-width: 13rem;
}
.audit-search-form .col-order-type {
  min-width: 10rem;
}
.audit-search-form .col-tds-datepicker {
  flex: 0 0 110px;
  max-width: 110px;
  padding: 0 15px;
  display: inline-flex;
}
.audit-search-form .tds-date-width {
  width: 5rem;
}
