.searchFinancialReports {
  border: 1px solid #ddd;
  padding: 1rem;
  background-color: #f0f0f0;
  display: inline-flex;
  margin-bottom: 2rem;
}
.searchFinancialReports .tds-financial-select {
  min-width: 15rem;
  padding: 0 15px;
}
.searchFinancialReports .tds-sales-select {
  min-width: 16rem;
  padding: 0 15px;
}
.searchFinancialReports .tds-region-select {
  min-width: 12rem;
  padding: 0 15px;
}
.searchFinancialReports .tds-datepicker-option {
  min-width: 12rem;
}
.searchFinancialReports .col-tds-datepicker {
  flex: 0 0 110px;
  max-width: 110px;
  padding: 0 15px;
  display: inline-flex;
}
.searchFinancialReports .tds-date-width {
  width: 5rem;
}
