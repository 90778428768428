.add-sub-details-modal .modal__contentContainer {
  min-width: 600px;
}
.add-sub-details-modal .modal-container {
  width: 100%;
  margin-right: 1rem;
  margin-left: 1rem;
}
.add-sub-details-modal .modal-container .modal-btns {
  margin-top: 1rem;
}
