.generalSettings, .subSettings, .transactionSettings {
  padding-top: 15px;
}
.generalSettings .tds-settings-label, .subSettings .tds-settings-label, .transactionSettings .tds-settings-label {
  width: 14rem;
  padding-right: 15px;
  padding-left: 15px;
}
.generalSettings .select__label, .subSettings .select__label, .transactionSettings .select__label {
  width: 11rem;
}
.generalSettings .select__container, .generalSettings .select__list, .subSettings .select__container, .subSettings .select__list, .transactionSettings .select__container, .transactionSettings .select__list {
  width: 12rem;
}
.generalSettings .select__message, .subSettings .select__message, .transactionSettings .select__message {
  color: #555555;
}
.generalSettings .integer-type .textField__container, .subSettings .integer-type .textField__container, .transactionSettings .integer-type .textField__container {
  width: 5rem;
}
.generalSettings .textField__message, .subSettings .textField__message, .transactionSettings .textField__message {
  color: #555555;
}
.generalSettings .tds-radio-label, .subSettings .tds-radio-label, .transactionSettings .tds-radio-label {
  padding-top: 1.25rem;
}
.generalSettings .radio__message, .subSettings .radio__message, .transactionSettings .radio__message {
  margin: 0;
  margin-top: -1rem;
  font-size: 0.75rem;
  text-align: left;
  min-height: 1rem;
  line-height: 1rem;
}
