.loginPage {
  max-width: 350px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.loginConfirmModal .modal__contentContainer {
  max-width: 700px;
}
