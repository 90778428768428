.tds-comments .comment-list {
  height: 200px;
  overflow-y: auto;
}
.tds-comments .comment {
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 0.5rem;
}
.tds-comments textarea {
  width: 100%;
}
.tds-comments .tdsSelect {
  margin-bottom: 0;
  float: right;
  text-transform: none;
}
.tds-comments .tdsSelect .select__container {
  padding-top: 0;
}
.tds-comments .tdsSelect .select__container::before {
  height: 0;
}
.tds-comments .tdsSelect .select__message {
  display: none;
}
.tds-comments .btn-del {
  padding: 0 !important;
  margin-right: 0.5rem;
}
.tds-comments .acd_h2 {
  border-bottom: 1px solid #DDDDDD;
}
