.create-promo-code-properties .discount-row {
  width: 300px;
}
.create-promo-code-properties .discount-row .tds-radio .form-check-inline {
  display: block;
}
.create-promo-code-properties .refund-value {
  min-width: 100px;
  margin-right: 1rem;
}
.create-promo-code-properties .tds-num-field {
  width: 200px;
}
.create-promo-code-properties .tds-date-width {
  width: 5rem;
}
