.user-card {
  background-color: #f0f0f0;
  display: inline-block;
  margin: 1rem 0 4rem;
  color: #333333;
}
.user-card .container {
  width: auto;
  max-width: unset;
  display: inline-flex;
  padding: 0 1.75rem;
}
.user-card .row {
  display: inline-flex;
}
.user-card .user-title {
  padding: 5px 0;
  text-transform: uppercase;
  text-align: center;
  background-color: #555555;
  color: #fff;
  font-size: 1rem;
  width: auto;
}
.user-card .auditlog-link-btn {
  text-align: center;
  margin-top: 9px;
}
.user-card .user-btn {
  padding: 0 1.75rem;
}
.user-card .delete-btn {
  text-align: center;
}
.user-card .delete-btn .btn-link {
  color: #e51837;
}
.user-card .user-card-btn {
  padding: 0 !important;
  margin-left: 0.75rem;
}
.user-card .tdsSelect {
  display: inline-block;
  margin-bottom: 0;
}
.user-card .tdsSelect .select__container {
  padding-top: 0;
}
