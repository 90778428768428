.manageUser .user-info {
  margin-bottom: 2rem;
}
.manageUser .tds-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  border-bottom: 1px solid #555555;
  margin-block-start: 0;
  padding-inline-start: 0;
  padding: 0;
}
.manageUser .tds-tabs .tds-tab {
  display: flex;
  flex-grow: 1;
  flex-flow: column wrap;
  align-items: flex-start;
}
.manageUser .tds-tabs .tds-tab > a {
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #333333;
  font-weight: 500;
}
.manageUser .tds-tabs .tds-tab > a.active {
  border-bottom: 4px solid #005dab;
}
