@media screen and (min-width: 600px) {
  .auditlog-modal {
    min-width: 540px;
  }
}
@media screen and (min-width: 600px) and (min-width: 600px) {
  .auditlog-modal td[data-label=adminComment] {
    max-width: 350px;
  }
}
@media screen and (min-width: 1300px) {
  .auditlog-modal {
    width: 80vw;
  }
}
.auditlog-modal .wrap {
  margin-top: -5rem;
  margin-bottom: 20px;
}
.auditlog-modal .loading-icon {
  margin-top: 6rem;
  margin-left: 5px;
}
